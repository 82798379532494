<template>
  <v-form
    v-model="valid"
    id="formOffer"
    autocomplete="off"
    ref="formOffer"
    lazy-validation
    @submit.prevent=""
  >
    <v-container class="px-0 pt-4 pb-0 mb-6">
      <v-col
        class="subject-box px-md-5 px-sm-3 py-5 pb-0"
        :class="{ 'mb-6': lengthSubjects > 1 }"
        cols="12"
        sm="11"
        md="8"
        lg="6"
        v-for="(subject, idx) in subjects"
        :key="`subj_${idx}`"
      >
        <h3 class="form-subtitle d-flex justify-space-between">
          {{ textSubTitle }}
          {{ lengthSubjects > 1 ? `№ ${idx + 1}` : "" }}
          <v-btn
            v-if="lengthSubjects > 1"
            icon
            @click="deleteSubject(idx)"
            :ripple="false"
            id="deleteSubject"
          >
            <v-icon color="grey darken-1">mdi-delete-forever</v-icon>
          </v-btn>
        </h3>
        <!--subject-wrap-->
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-select
              v-model="subject.type"
              id="type"
              :ref="`type_${idx}`"
              :items="objectDetails"
              :item-disabled="subject.disabled"
              item-text="nameDesc"
              item-value="value"
              :rules="rules.type"
              :label="labelSubjectType"
              @change="changeType(idx)"
              color="privat"
              required
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-radio-group
              v-model="subject.condition"
              id="condition"
              class="mt-1"
              dense
              row
              @change="changeCondition(idx)"
            >
              <v-radio
                v-for="n in subject.conditionTypes"
                :key="n.value"
                :label="n.label"
                :value="n"
                :disabled="n.disabled"
                class="mr-4"
                color="privat"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="subject.showSubObjs">
          <v-col cols="12">
            <v-select
              v-model="subject.subType"
              id="type"
              :ref="`subType_${idx}`"
              :items="subject.subObjs"
              :item-disabled="subject.disabled"
              item-text="nameDesc"
              item-value="id"
              :rules="showSubType ? rules.subType : []"
              :label="$t('label.leasingSubType')"
              color="privat"
              :required="showSubType"
              outlined
              dense
              @change="changeRate"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-text-field
              v-model="subject.brand"
              id="brand"
              :rules="subject.brand ? rules.brand : []"
              :label="$t('label.brand')"
              color="privat"
              maxlength="50"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-text-field
              v-model="subject.model"
              id="model"
              :rules="subject.model ? rules.model : []"
              :label="$t('label.model')"
              color="privat"
              maxlength="50"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="subject.equipment"
              id="equipment"
              name="equipment"
              :label="
                subject.equipment
                  ? $t('label.equipment')
                  : $t('label.equipment_holder')
              "
              :rules="subject.equipment ? rules.equipment : []"
              color="privat"
              maxlength="255"
              counter="255"
              rows="3"
              outlined
              dense
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            :sm="subject.sizeCost"
            :md="subject.sizeCost"
            :lg="subject.sizeCost"
          >
            <v-text-field
              v-model="subject.cost"
              id="cost"
              :ref="`cost_${idx}`"
              :rules="rules.cost"
              :label="
                !subject.cost ? $t('label.cost_holder') : $t('label.cost')
              "
              maxlength="12"
              color="privat"
              required
              outlined
              dense
              @input="setSize(idx, 'sizeCost')"
              @blur="discountCalc('money', idx, 'cost')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="11"
            :sm="subject.sizeCost"
            :md="subject.sizeCost"
            :lg="subject.sizeCost"
          >
            <v-select
              v-model="subject.prepayment"
              id="prepayment"
              :ref="`prepayment_${idx}`"
              :items="subject.prePaymentTypes"
              item-text="label"
              item-value="_val"
              :rules="rules.prepayment"
              :label="$t('label.prepayment')"
              color="privat"
              required
              outlined
              dense
              @change="changeRate"
              @focus="idxMutationSubject = idx"
            >
            </v-select>
          </v-col>
          <v-col
            cols="1"
            class="pt-2 pl-1"
            v-if="subject.cost && subject.prepayment"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="grey">
                  mdi-information
                </v-icon>
              </template>
              <span>
                {{ $t("offer.tooltip.prepayment") }}<br />
                <span>
                  {{ subject._finalPayment }} = {{ subject.cost | cost }} -
                  {{ subject.prepayment }} %
                </span>
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            :sm="subject.sizeLower"
            :md="subject.sizeLower"
            :lg="subject.sizeLower"
          >
            <v-text-field
              v-model="subject.lowerPercent"
              id="lowerPercent"
              :rules="
                subject.cost && subject.lowerPercent ? rules.lowerPercent : []
              "
              :label="$t('label.lowerPercent')"
              :disabled="financing579 || financingCR"
              color="privat"
              outlined
              dense
              @blur="discountCalc('money', idx, 'lowerPercent')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="11"
            :sm="subject.sizeLower"
            :md="subject.sizeLower"
            :lg="subject.sizeLower"
          >
            <v-text-field
              v-model="subject.lowerMoney"
              id="lowerMoney"
              :rules="
                subject.cost && subject.lowerMoney ? rules.lowerMoney : []
              "
              :label="$t('label.lowerMoney')"
              :disabled="financing579 || financingCR"
              color="privat"
              outlined
              dense
              @blur="discountCalc('percent', idx, 'lowerMoney')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="1"
            class="pt-2 pl-1"
            v-if="subject.lowerPercent && subject.lowerMoney"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="grey">
                  mdi-information
                </v-icon>
              </template>
              <span>
                {{ $t("offer.tooltip.discount") }}<br />
                <span>
                  {{ subject._finalCost }} = {{ subject.cost | cost }} -
                  {{ subject.lowerMoney | cost }}
                </span>
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="additional-services">
          <v-col cols="12" v-if="subject.reg && subject.reg.show">
            <v-checkbox
              v-model="subject.needReg"
              class="mt-0"
              :label="
                `${$t('label.registration', {
                  sumReg: subject.reg.sum
                })}`
              "
              @change="changeRate"
              :disabled="subject.reg.disabled"
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="subject.needPF"
              v-if="!loadPF && subject.PF.show"
              class="mt-0"
              @change="changeRate"
              :disabled="subject.PF.disabled"
            >
              <template v-slot:label>
                {{ $t("label.pensionFund") }}
                {{ `(${subject.PF.sum ? subject.PF.sum.toFixed(2) : ""} грн)` }}
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="subject.needPF"
              v-if="loadPF && subject.PF && subject.PF.show"
              class="mt-0"
              :label="`${$t('label.pensionFund')} ( грн)`"
              :disabled="subject.PF.disabled"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="11"
        md="8"
        lg="6"
        class="px-0 mt-6"
        v-if="lengthSubjects < max_subject"
      >
        <v-btn
          id="add-subject"
          color="blue-grey lighten-5"
          elevation="1"
          width="100%"
          height="39px"
          @click="addSubject"
        >
          {{ financingCR ? $t("action.addEstate") : $t("action.addSubject") }}
        </v-btn>
      </v-col>
    </v-container>
    <v-col cols="12" sm="11" md="8" lg="6" class="px-0">
      <v-divider></v-divider>
    </v-col>
    <v-container class="px-0 pt-6 pb-4">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" id="compensationPartner">
          <h3 class="form-subtitle mb-2 d-flex">
            {{ $t("offer.subTitle.compensationPartner") }}
          </h3>
          <v-switch
            v-model="compensationPartner"
            class="mt-0 pt-0"
            color="privat"
            @change="changeCompensation"
          >
            <template v-slot:label>
              <label class="ml-3">
                <span class="font-weight-bold">
                  {{
                    compensationPartner ? $t("label.true") : $t("label.false")
                  }}
                </span>
              </label>
            </template>
          </v-switch>
        </v-col>
      </v-row>
      <v-row v-if="compensationPartner">
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            v-model="generalParams.typeCompens"
            id="typeCompensation"
            :items="options.typesCompensation"
            item-text="label"
            item-value="value"
            :rules="compensationPartner ? rules.typeCompens : []"
            :required="compensationPartner"
            :label="$t('label.typeCompensation')"
            color="privat"
            outlined
            dense
            @change="changeRate"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="compensationPartner">
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-text-field
            v-model="generalParams.percentComp"
            id="percentCompensation"
            :rules="compensationPartner ? rules.percentComp : []"
            :required="compensationPartner"
            :label="$t('label.percentCompensation')"
            color="privat"
            outlined
            dense
            @blur="formatCompensation($event)"
          ></v-text-field>
        </v-col>
      </v-row>
      <h3 class="form-subtitle mt-2">{{ $t("offer.subTitle.general") }}</h3>
      <v-row>
        <v-col cols="11" sm="6" md="4" lg="3">
          <v-select
            v-model="generalParams.term"
            id="term"
            :items="options.periodTypes"
            item-text="label"
            item-value="value"
            :rules="rules.term"
            :label="$t('label.term')"
            color="privat"
            required
            outlined
            dense
            @change="changeRate"
          >
          </v-select>
        </v-col>
        <v-col cols="1" class="pt-2 pl-1">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="grey">
                mdi-information
              </v-icon>
            </template>
            <span v-html="$t('offer.tooltip.term')"></span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            v-model="generalParams.schedule"
            id="schedule"
            :items="options.schedules"
            item-text="label"
            item-value="value"
            :rules="rules.schedule"
            :label="$t('label.schedule')"
            color="privat"
            required
            outlined
            dense
            @change="generalParams.adaptMonths = []"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="generalParams.schedule === 'CUSTOM'">
        <v-col cols="11" sm="6" md="4" lg="3">
          <v-select
            v-model="generalParams.adaptMonths"
            id="adaptMonths"
            :items="options.adaptDetails"
            item-text="label"
            :rules="generalParams.schedule ? rules.adaptDetails : []"
            :label="$t('label.adaptMonths')"
            color="privat"
            multiple
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col
          cols="1"
          class="pt-2 pl-1"
          v-if="generalParams.adaptMonths.length && !coincidingMonth.length"
        >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="red">
                mdi-alert-circle
              </v-icon>
            </template>
            <span v-html="$t('offer.tooltip.rangeMonths')"></span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-menu
            v-model="showDatePiker"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormattedMoment"
                :label="$t('label.firstPaymentDate')"
                append-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="generalParams.firstPaymentDate"
              id="firstPaymentDate"
              :min="minDate"
              :locale="langDatePiker"
              :first-day-of-week="1"
              @change="showDatePiker = false"
              color="privat"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="financingCR">
        <v-col cols="12" class="mb-6" id="stateGuarantee">
          <h3 class="form-subtitle mb-2 d-flex">
            {{ $t("offer.subTitle.stateGuarantee") }}
          </h3>
          <div class="d-flex align-start">
            <v-switch
              v-model="generalParams.stateGuarantee"
              class="mt-0 pt-0"
              color="privat"
              @change="changeRate"
              :disabled="!generalParams.editGuarantee"
            >
              <template v-slot:label>
                <label class="ml-3">
                  <span class="font-weight-bold">
                    {{
                      generalParams.stateGuarantee
                        ? $t("label.true")
                        : $t("label.false")
                    }}
                  </span>
                </label>
              </template>
            </v-switch>
            <!--<a
              href="https://bank.gov.ua/files/UIRD.xls"
              v-if="!financingCR579 && generalParams.stateGuarantee"
            >
              <v-icon>mdi-download</v-icon>
              UIRD
            </a>
            <v-tooltip
              right
              max-width="420"
              v-if="!financingCR579 && generalParams.stateGuarantee"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="grey" class="ml-3">
                  mdi-information
                </v-icon>
              </template>
              {{ $t("offer.tooltip.stateGuarantee") }}
            </v-tooltip>-->
          </div>
        </v-col>
      </v-row>
      <v-row v-if="showUridTypes">
        <v-col cols="11" sm="6" md="4" lg="3" class="mb-2">
          <v-select
            v-model="generalParams.uridId"
            id="urid"
            :items="options.uirdTypes"
            item-text="label"
            item-value="value"
            :rules="generalParams.term ? rules.uridId : []"
            :label="$t('label.urid')"
            color="privat"
            required
            outlined
            dense
            @change="changeUrid"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="loadFinancingRate">
        <v-col cols="12" sm="6" md="4" lg="3" class="mb-2">
          <div class="loader-wrap loader-wrap__financing-rate">
            <v-progress-circular
              :size="30"
              color="privat"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="showFinancingRate">
        <v-col cols="12" class="mb-2">
          <h3 class="form-subtitle mb-3">
            {{ $t("offer.subTitle.program579s") }}
          </h3>
          <v-radio-group
            v-model="generalParams.financingRate"
            id="financing"
            class="mt-1 pl-0"
            dense
            column
            @change="changeRate"
          >
            <v-radio
              v-for="n in options.program579s"
              :key="n.value"
              :label="n.label"
              :value="n.value"
              :disabled="n.disabled"
              class="mr-4"
              color="privat"
            >
              <template slot="label">
                <span class="mr-3">{{ n.label }}</span>
                <!--<v-tooltip right v-if="n.tooltip" max-width="375">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="grey">
                      mdi-information
                    </v-icon>
                  </template>
                  <span v-html="n.tooltip"></span>
                </v-tooltip>-->
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" sm="6" md="4" lg="3">
          <!--<v-text-field
            v-if="financingCR"
            id="rate"
            v-model="generalParams.rate"
            :label="`${$t('label.rate')} ${$t('options.annual')}`"
            :rules="generalParams.stateGuarantee ? rules.rate : []"
            color="privat"
            :disabled="financingCR579 || !generalParams.stateGuarantee"
            outlined
            dense
            @blur="formatRate"
          >
          </v-text-field>-->
          <v-text-field
            id="rate"
            :value="rate"
            :label="
              rate
                ? $t('label.rate')
                : `${$t('label.rate')} ${$t('options.annual')}`
            "
            color="privat"
            disabled
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="1" class="pt-2 pl-1" v-show="rate && rateUIRD">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="grey">
                mdi-information
              </v-icon>
            </template>
            <span v-html="$t('offer.tooltip.rate', { UIRD: rateUIRD })"></span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-text-field
            id="rate"
            :value="
              generalParams.onceCommission
                ? `${generalParams.onceCommission} грн`
                : ''
            "
            :label="$t('label.onceCommission')"
            color="privat"
            disabled
            outlined
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row id="individual-conditions" v-if="showIndRate || showIndCommission">
        <v-col cols="12" sm="6" md="4" lg="3">
          <h3 class="form-subtitle mb-2 d-flex">
            {{ $t("offer.subTitle.individualConditions") }}
            <v-tooltip right max-width="400" min-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="grey" class="ml-2">
                  mdi-information
                </v-icon>
              </template>
              {{ $t("offer.tooltip.individualConditionsDescr") }}
            </v-tooltip>
          </h3>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="showIndRate">
          <v-checkbox
            v-model="individualRate"
            id="indRate"
            class="detail-checkbox mt-0"
            :class="{ 'mb-6': !showIndCommission }"
            :label="$t('label.indRate')"
            hide-details="true"
            color="privat"
            @change="restIndividualRate"
          ></v-checkbox>
          <template v-if="individualRate">
            <v-text-field
              v-model="generalParams.indRate"
              id="indRateValue"
              class="mt-3"
              :label="$t('label.indRateValue')"
              color="privat"
              outlined
              dense
              :required="individualRate"
              :rules="individualRate ? rules.required : []"
              @blur="getOnceCommission('indRate')"
            >
            </v-text-field>
          </template>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="showIndCommission">
          <v-checkbox
            v-model="individualCommission"
            id="indCommission"
            :class="{
              'mb-6': !individualCommission,
              'mt-0': !showIndRate || individualRate
            }"
            class="detail-checkbox"
            :label="$t('label.indCommission')"
            hide-details="true"
            color="privat"
            @change="restIndividualCommission"
          ></v-checkbox>
          <template v-if="individualCommission">
            <v-radio-group
              v-model="generalParams.indType"
              id="indType"
              class="mt-3 pl-0 ml-8"
              dense
              column
              :required="individualCommission"
              :rules="individualCommission ? rules.required : []"
              @change="getOnceCommission"
            >
              <v-radio
                v-for="n in options.typesAccrual"
                :key="n.value"
                :label="n.label"
                :value="n.value"
                :disabled="n.disabled"
                class="mr-4"
                color="privat"
              >
                <template slot="label">
                  <span class="mr-3">{{ n.label }}</span>
                </template>
              </v-radio>
            </v-radio-group>
            <v-text-field
              v-model="generalParams.indPrComm"
              id="indCommissionValue"
              class="mt-3"
              :label="$t('label.indCommissionValue')"
              color="privat"
              :disabled="!generalParams.indType"
              :required="individualCommission"
              :rules="individualCommission ? rules.individualCommission : []"
              outlined
              dense
              @blur="getOnceCommission('indPrComm')"
            >
            </v-text-field>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-btn
            id="continue"
            elevation="1"
            color="privat"
            width="100%"
            height="39px"
            @click="validateStep"
          >
            <span class="white--text">
              {{ $t("action.continue") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
      <base-snackbar :props="snack" :show="show" @close="show = false" />
    </v-container>
    <OfferTotal
      :calc-pledge="calcPledge"
      :length="lengthSubjects"
      :program="program"
      v-if="!overlay"
    />
  </v-form>
</template>

<script>
import costFilter from "@/filters/cost.filter";
import showSnack from "@/mixins/showSnack.mixins";
import moment from "moment";
import CONST from "@/utils/constants";
import errorHandler from "@/utils/errorHandler";
import * as offerService from "@/services/offer.service";
import OfferTotal from "@/components/offer/OfferTotal";
import { mapGetters } from "vuex";
import createMockData from "@/mock/mockNewData";

export default {
  name: "OfferStepTwo",
  mixins: [showSnack],
  props: {
    pledge: {
      type: Array,
      default: () => []
    },
    general: {
      type: Object,
      default: () => ({})
    },
    client: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    },
    cashRate: {
      type: Number
    },
    overlay: {
      type: Boolean,
      default: false
    },
    program: {
      type: String
    }
  },
  components: {
    OfferTotal
  },
  data() {
    return {
      valid: true,
      max_subject: CONST.MAX_SUBJECT,
      subjects: [
        {
          type: "",
          subType: false,
          condition: null,
          brand: "",
          model: "",
          equipment: "",
          cost: "",
          prepayment: "",
          lowerPercent: "",
          lowerMoney: "",
          discount: "",
          conditionTypes: [],
          prePaymentTypes: [],
          needReg: null,
          needPF: null,
          PF: {},
          sizeCost: 6,
          sizeLower: 6,
          _finalCost: null,
          _finalPayment: null
        }
      ],
      generalParams: {
        term: "",
        schedule: "",
        adaptMonths: "",
        firstPaymentDate: null,
        rate: null,
        onceCommission: "",
        financingRate: null,
        stateGuarantee: false,
        editGuarantee: false,
        uridId: "",
        typeCompens: null,
        percentComp: null,
        indRate: null,
        indType: null,
        indPrComm: null
      },
      objectDetails: [],
      prePaymentTypes: [],
      minDate: moment().format("YYYY-MM-DD"),
      showDatePiker: false,
      langDatePiker: null,
      idxMutationSubject: 0,
      calcPledge: null,
      loadPF: false,
      objectDetail: {},
      rateUIRD: "",
      loadFinancingRate: false,
      compensationPartner: false,
      individualRate: false,
      individualCommission: false
    };
  },
  computed: {
    ...mapGetters(["getPointOfEntry", "devEnv"]),
    rules() {
      return {
        type: [v => !!v || this.$i18n.t("rules.required")],
        subType: [v => !!v || this.$i18n.t("rules.required")],
        brand: [
          v => v.length >= 2 || this.$i18n.t("rules.length.min_2"),
          v => v.length <= 50 || this.$i18n.t("rules.length.max_50")
        ],
        model: [v => v.length <= 50 || this.$i18n.t("rules.length.max_50")],
        spec: [
          v =>
            v.size <= CONST.MAX_FILE_SIZE || this.$i18n.t("rules.file.max_size")
        ],
        equipment: [
          v => v.length <= 255 || this.$i18n.t("rules.length.max_255")
        ],
        cost: [
          v => !!v || this.$i18n.t("rules.required"),
          v => v <= CONST.MAX_COST || this.$i18n.t("rules.cost.max"),
          v =>
            /^[0-9]{1,9}$/.test(v) ||
            /^[0-9]+[.,][0-9]{1,2}$/.test(v) ||
            this.$i18n.t("rules.cost.correct")
        ],
        lowerPercent: [
          v =>
            /^[0-9]{1,2}$/.test(v) ||
            /^[0-9]{1,2}[.,][0-9]{1,2}$/.test(v) ||
            this.$i18n.t("rules.lowerPercent.correct")
        ],
        lowerMoney: [
          v =>
            /^[0-9]+$/.test(v) ||
            /^[0-9]+[.,][0-9]{1,2}$/.test(v) ||
            this.$i18n.t("rules.lowerMoney.correct")
        ],
        prepayment: [v => !!v || this.$i18n.t("rules.required")],
        term: [v => !!v || this.$i18n.t("rules.required")],
        schedule: [v => !!v || this.$i18n.t("rules.required")],
        adaptDetails: [
          v => !!v.length || this.$i18n.t("rules.required"),
          v => v.length >= 2 || this.$i18n.t("rules.adaptDetails.min"),
          v => v.length <= 6 || this.$i18n.t("rules.adaptDetails.max"),
          v => (v && !!this.coincidingMonth.length) || ""
        ],
        rate: [
          v => !!v || this.$i18n.t("rules.required"),
          v =>
            /^[0-9]{1,2}$/.test(v) ||
            /^[0-9]+[.,][0-9]{1,2}$/.test(v) ||
            this.$i18n.t("rules.rate.correct")
        ],
        uridId: [v => !!v || this.$i18n.t("rules.required")],
        typeCompens: [v => !!v || this.$i18n.t("rules.required")],
        percentComp: [
          v => !!v || this.$i18n.t("rules.required"),
          v =>
            /^[0-9]{1,2}$/.test(v) ||
            /^[0-9]{1,2}[.,][0-9]{1,2}$/.test(v) ||
            this.$i18n.t("rules.percentComp.correct")
        ],
        individualCommission: [
          v => (!!v && v !== 0) || this.$i18n.t("rules.required")
        ],
        required: [v => !!v || this.$i18n.t("rules.required")]
      };
    },
    computedDateFormattedMoment() {
      let firstPaymentDate = this.generalParams.firstPaymentDate;

      return firstPaymentDate
        ? moment(firstPaymentDate).format("DD-MM-YYYY")
        : null;
    },
    rangeMonths() {
      let months = [];
      let allMonths = this.options.adaptDetails.map(el => el._val);
      let firstMonth = +moment(this.generalParams.firstPaymentDate)
        .format("M")
        .toUpperCase();

      if (firstMonth <= 5) {
        months = allMonths.slice(
          firstMonth - 1,
          allMonths.indexOf(firstMonth + 7)
        );
      }
      if (firstMonth >= 6) {
        months = allMonths.slice(firstMonth - 1);
        if (firstMonth > 6) {
          let x = firstMonth - 6;
          for (let i = 1; i <= x; i++) {
            months.push(i);
          }
        }
      }

      return months;
    },
    coincidingMonth() {
      let selectedMonths = this.generalParams.adaptMonths.map(el => {
        return this.options.adaptDetails.find(i => i.value === el)._val;
      });

      return this.rangeMonths.filter(n => !!~selectedMonths.indexOf(n));
    },
    lengthSubjects() {
      return this.subjects.length;
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    financing579() {
      return this.program === "FL_579";
    },
    financingCR() {
      return this.program.includes("CR");
    },
    financingCR579() {
      return this.program === "CR_579";
    },
    financingRate() {
      if (this.generalParams.financingRate) {
        let rate = +this.generalParams.financingRate.split("_")[1];

        return rate.toFixed(2);
      }

      return null;
    },
    showSubType() {
      return this.subjects[this.idxMutationSubject].type === "commercial_auto";
    },
    textSubTitle() {
      return this.financingCR
        ? this.$i18n.t("offer.subTitle.estate")
        : this.$i18n.t("offer.subTitle.subject");
    },
    labelSubjectType() {
      return this.financingCR
        ? this.$i18n.t("label.estateSubject")
        : this.$i18n.t("label.leasingSubject");
    },
    stateGuarantee() {
      return this.general.stateGuarantee;
    },
    rate() {
      if (this.generalParams.rate) {
        return `${this.generalParams.rate} ${this.$i18n.t("options.annual")}`;
      }

      return null;
    },
    showUridTypes() {
      return (
        this.options.uirdTypes.length &&
        (this.financing579 || this.financingCR579)
      );
    },
    showFinancingRate() {
      return (
        this.options.program579s.length &&
        (this.financing579 || this.financingCR579)
      );
    },
    showIndRate() {
      return this.general.flIndRate;
    },
    showIndCommission() {
      return this.general.flIndPrComm;
    }
  },
  methods: {
    async validateStep() {
      if (this.$refs.formOffer.validate()) {
        this.$emit("progress", true);
        let data = this.createSendData(this.subjects, this.generalParams);

        this.sendData(data);
      } else {
        this.showSnack("error", [this.$i18n.t(`error.EMPTY_FIELD`)]);
        return false;
      }
    },
    changeCompensation() {
      if (!this.compensationPartner) {
        this.generalParams.typeCompens = null;
        this.generalParams.percentComp = null;
        this.changeRate();
      }
    },
    formatCompensation(ev) {
      const value = this.formatNumAndToFixed(this.generalParams.percentComp);

      ev.target.value = value;
      this.generalParams.percentComp = value;
      this.changeRate();
    },
    formatRate() {
      this.generalParams.rate = this.formatNumAndToFixed(
        this.generalParams.rate
      );
    },
    formatNumAndToFixed(str, withZero) {
      let newStr = null;

      if (typeof str === "string" && str.includes(",")) {
        newStr = +str.replace(",", ".");
        if (withZero) {
          return newStr || newStr === 0 ? newStr.toFixed(2) : null;
        }

        return newStr ? newStr.toFixed(2) : null;
      } else {
        newStr = +str;
        if (withZero) {
          return newStr || newStr === 0 ? newStr.toFixed(2) : null;
        }

        return newStr ? newStr.toFixed(2) : null;
      }
    },
    getPF(idx) {
      let cost, type, id;

      this.loadPF = true;
      cost = this.subjects[idx]?.cost;
      type = this.subjects[idx]?.type;
      if (!this.subjects[idx].PF.show || !cost || !type) {
        return false;
      }

      id = this.findObjectDetail(this.subjects[idx].type).id;

      if (this.devEnv) {
        //esa/cp/calcPF?cost=1500000&id=2
        this.subjects[idx].PF.sum = 75000.0;
        this.loadPF = false;
        // mock
        return;
      }

      offerService
        .getPensionFund(cost, id, this.authEsa)
        .then(res => {
          this.subjects[idx].PF.sum = res.data;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getPensionFund");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getPF");
        })
        .finally(() => {
          this.loadPF = false;
        });
    },
    discountCalc(mode, idx, props) {
      let cost, discount, result;

      if (props === "cost") this.getPF(idx);
      this.idxMutationSubject = idx;
      this.subjects[idx][props] = this.subjects[idx][props]
        ? this.formatNumAndToFixed(this.subjects[idx][props])
        : null;
      this.changeRate();
      if (!this.subjects[idx].lowerPercent && !this.subjects[idx].lowerMoney) {
        return false;
      }

      cost = this.subjects[idx].cost;
      if (mode === "money") {
        discount = this.subjects[idx].lowerPercent;
        result = (cost / 100) * discount;
        this.subjects[idx].lowerMoney = result ? +result.toFixed(2) : null;
      } else {
        discount = this.subjects[idx].lowerMoney;
        result = (discount / cost) * 100;
        this.subjects[idx].lowerPercent = result ? +result.toFixed(2) : null;
      }
      this.setSize(idx, "sizeLower");
    },
    changeRate() {
      const notEmptySubjects = this.subjects.filter(
        el => el.cost && el.prepayment && el.type
      );

      if (this.lengthSubjects === notEmptySubjects.length) {
        this.getRateOrCalcDetails(true);

        if (
          this.compensationPartner &&
          (!this.generalParams.percentComp || !this.generalParams.typeCompens)
        ) {
          return;
        }

        if (!this.financing579 && !this.financingCR579) {
          this.generalParams.term ? this.getRateOrCalcDetails() : false;
        } else {
          this.generalParams.term && this.generalParams.uridId
            ? this.getRateOrCalcDetails()
            : false;
        }
      }
      this.setSize(this.idxMutationSubject, "sizeCost");
    },
    getRateOrCalcDetails(calc) {
      let sendData = null;

      const pledgeDetails = this.subjects
        .map(el => {
          if (el.cost && el.prepayment) {
            let objectDetail = this.findObjectDetail(el.type);
            let data = {
              cost: el.cost,
              discount: el.lowerPercent || 0,
              prepayment: `P${el.prepayment}`,
              conditionType: el.condition.value,
              needReg: el.needReg,
              needPF: el.needPF
            };

            if (calc) {
              data.objDetailId = objectDetail.id;
              el.subType ? (data.subId = el.subType) : false;
            } else {
              data.objectDetail = { id: objectDetail.id };
              el.subType ? (data.subId = el.subType) : false;
            }

            return data;
          }
        })
        .filter(el => el);

      if (calc) {
        sendData = {
          cpId: this.client.cpId,
          pledgeDetails: pledgeDetails
        };
      } else {
        sendData = {
          cpId: this.client.cpId,
          program579: this.generalParams.financingRate,
          periodType: this.generalParams.term,
          pledgeDetails: pledgeDetails,
          stateGuarant: this.generalParams.stateGuarantee
        };

        if (this.compensationPartner) {
          sendData.typeCompens = this.generalParams.typeCompens;
          sendData.percentComp = +this.generalParams.percentComp;
        }
        if (this.individualRate) {
          sendData.indRate = +this.generalParams.indRate;
        }
        if (this.individualCommission) {
          sendData.indType = this.generalParams.indType;
          sendData.indPrComm = +this.generalParams.indPrComm;
        }
      }

      if (this.devEnv) {
        if (calc) {
          this.calcPledge = createMockData("calcPledgeDetailsEsa");
        } else {
          const data = createMockData("getPledgeDetailsStakeEsa");

          this.generalParams.rate = data.interestRate.toFixed(2);
          this.generalParams.onceCommission = data.onceCommissionSum.toFixed(2);
          this.rateUIRD = data.rateUIRD;
        }
        // mock
        return;
      }

      offerService
        .getStakeOrCalcPledgeDetails(sendData, calc, this.authEsa)
        .then(res => {
          if (calc) {
            this.calcPledge = res.data;
          } else {
            this.generalParams.rate = res.data.interestRate.toFixed(2);
            this.generalParams.onceCommission = res.data.onceCommissionSum.toFixed(
              2
            );
            this.rateUIRD = res.data.rateUIRD;
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err, "calcPledgeDetails");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          if (this.cashRate) {
            this.generalParams.rate = this.cashRate;
          } else if (this.program !== "CR") {
            this.generalParams.rate = this.options.rate;
          }
          if (this.financing579 || this.financingCR579) {
            this.generalParams.rate = this.financingRate;
          }
          console.log("catch getStakeOrCalcPledgeDetails");
        });
    },
    getOnceCommission(props) {
      if (this.generalParams[props]) {
        if (props === "indPrComm") {
          this.generalParams[props] = this.formatNumAndToFixed(
            this.generalParams[props],
            true
          );
        } else {
          this.generalParams[props] = this.formatNumAndToFixed(
            this.generalParams[props]
          );
        }

        if (!this.$refs.formOffer.validate()) {
          this.showSnack("error", [this.$i18n.t(`error.EMPTY_FIELD`)]);
          return false;
        }

        this.getRateOrCalcDetails();
      }
    },
    restIndividualRate(val) {
      if (!val) {
        this.generalParams.indRate = null;
        this.changeRate();
      }
    },
    restIndividualCommission(val) {
      if (!val) {
        this.generalParams.indType = null;
        this.generalParams.indPrComm = null;
        this.changeRate();
      }
    },
    changeUrid() {
      let urid = this.generalParams.uridId;

      if (this.devEnv) {
        const data = createMockData("changeUrid");

        this.$emit("updateFormOptions", data);
        this.generalParams.financingRate = data?.value || null;
        this.changeRate();
        this.loadFinancingRate = false;
        // mock
        return;
      }

      this.loadFinancingRate = true;
      offerService
        .sendUrid(this.client.cpId, urid, this.authEsa)
        .then(res => {
          this.$emit("updateFormOptions", res.data);
          this.generalParams.financingRate = res.data?.value || null;
          this.changeRate();
        })
        .catch(err => {
          let errorCode = errorHandler(err, "REQUEST_DATA_INVALID");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch sendUrid");
        })
        .finally(() => {
          this.loadFinancingRate = false;
        });
    },
    createSendData(subjects, general) {
      let sendData = {
        cpId: this.client.cpId,
        periodType: general.term,
        scheduleType: general.schedule,
        firstPaymentDate: moment(general.firstPaymentDate).format("DD-MM-YYYY"),
        pledgeDetails: []
      };

      if (this.compensationPartner) {
        sendData.typeCompens = general.typeCompens;
        sendData.percentComp = +general.percentComp;
      }
      if (this.individualRate) {
        sendData.indRate = +this.generalParams.indRate;
      }
      if (this.individualCommission) {
        sendData.indType = this.generalParams.indType;
        sendData.indPrComm = +this.generalParams.indPrComm;
      }
      if (this.financing579 || this.financingCR579) {
        sendData.program579 = general.financingRate;
      }
      if (this.financingCR && !this.financingCR579) {
        sendData.stake = +general.rate;
      }
      if (this.financingCR) {
        sendData.stateGuarant = general.stateGuarantee;
      }

      general.adaptMonths
        ? (sendData.scheduleDetail = general.adaptMonths)
        : false;
      sendData.pledgeDetails = subjects.map(el => {
        let objDetail = this.findObjectDetail(el.type);
        let newPledge = {};

        newPledge.objDetailId = objDetail.id;
        el.subType ? (newPledge.subId = el.subType) : false;
        newPledge.conditionType = el.condition.value;
        newPledge.cost = el.cost;
        newPledge.prepayment = `P${el.prepayment}`;
        el.brand ? (newPledge.brand = el.brand) : false;
        el.model ? (newPledge.model = el.model) : false;
        el.equipment ? (newPledge.equipmentDetail = el.equipment) : false;
        el.lowerPercent ? (newPledge.discount = el.lowerPercent) : false;
        newPledge.needReg = el.needReg;
        newPledge.needPF = el.needPF;

        return newPledge;
      });

      return sendData;
    },
    sendData(data) {
      offerService
        .createOffer(data, this.authEsa)
        .then(() => {
          this.getStateCreate();
        })
        .catch(err => {
          let errorCode = errorHandler(err, "createOffer");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          this.$emit("progress", false);
          console.log("catch sendData");
        });
    },
    getStateCreate() {
      offerService
        .currentCondition(this.client.cpId, this.authEsa)
        .then(res => {
          this.checkStateCreate(res.data);
        })
        .catch(err => {
          let errorCode = errorHandler(err, "createOffer");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          this.$emit("progress", false);
          console.log("catch getStateCreate");
        });
    },
    checkStateCreate(data) {
      let error = data?.errorMessage;
      let errMsg = error ? error[0] : [this.$i18n.t("error.createOffer")];
      let state = data?.content?.state ?? "";
      let finalState = data?.finalRespState;

      if (error && state.includes("ERROR")) {
        this.showSnack("error", [this.$i18n.t(`error.${errMsg}`)]);
        this.$emit("progress", false);
        console.log("checkStateCreate error - ", error);
        return false;
      }

      if (!finalState) {
        this.timeOut = setTimeout(() => {
          this.getStateCreate();
        }, 2000);
      } else {
        this.$emit("update", [this.subjects, this.generalParams, true]);
      }
    },
    stepBack() {
      this.$emit("update", [this.subjects, this.generalParams, false]);
      this.$emit("step", [1, false]);
    },
    changeType(idx) {
      this.objectDetail = this.findObjectDetail(this.subjects[idx].type);
      if (this.subjects[idx].type === "commercial_auto") {
        this.setSubObjs(this.objectDetail, idx);
      } else {
        this.clearSubObjs(idx);
      }
      this.idxMutationSubject = idx;
      this.canBeSecondHand(this.objectDetail, idx);
      this.changePrePaymentTypes(this.objectDetail, idx);
      this.canRegAndPF(this.objectDetail, idx);
      this.getPF(idx);
    },
    setSubObjs(detail, idx) {
      this.subjects[idx].subObjs = detail.subObjs ? detail.subObjs : [];
      this.subjects[idx].showSubObjs = !!detail.subObjs;
    },
    clearSubObjs(idx) {
      this.subjects[idx].showSubObjs = false;
      delete this.subjects[idx].subType;
    },
    canBeSecondHand(detail, idx) {
      if (!detail.secondHandPrepayment) {
        this.subjects[idx].conditionTypes[1].disabled = true;
        this.subjects[idx].condition = this.subjects[idx].conditionTypes[0];
      } else {
        this.subjects[idx].conditionTypes[1].disabled = false;
      }
    },
    canRegAndPF(detail, idx) {
      let condition = this.subjects[idx].condition.value;

      if (condition === "NEW") {
        this.subjects[idx].reg = newObject(detail.regNew);
        this.subjects[idx].needReg = detail.regNew.value;
        this.subjects[idx].PF = newObject(detail.PFNew);
        this.subjects[idx].needPF = detail.PFNew.value;
      } else {
        this.subjects[idx].reg = newObject(detail.regSh);
        this.subjects[idx].needReg = detail.regSh.value;
        this.subjects[idx].PF = newObject(detail.PFSh);
        this.subjects[idx].needPF = detail.PFSh.value;
      }

      function newObject(object) {
        return Object.assign({}, object);
      }
    },
    changeCondition(idx) {
      this.objectDetail = this.findObjectDetail(this.subjects[idx].type);
      if (this.objectDetail) {
        this.changePrePaymentTypes(this.objectDetail, idx);
        this.canRegAndPF(this.objectDetail, idx);
        this.getPF(idx);
      }
    },
    changePrePaymentTypes(detail, idx) {
      let minStake = null;
      let condition = this.subjects[idx].condition.value;

      if (condition === "NEW") {
        minStake = detail.newPrepayment;
      } else {
        minStake = detail.secondHandPrepayment;
      }
      this.subjects[idx].prepayment = null;
      this.setSize(idx, "sizeCost");
      this.subjects[idx].prePaymentTypes = this.options.prepaymentTypes.filter(
        el => {
          return el._val >= minStake;
        }
      );
    },
    setDefaultPropsSubject(idx) {
      let conditionTypes = this.options.conditionTypes.map(el => {
        return {
          ...el
        };
      });

      this.subjects[idx].conditionTypes = conditionTypes;
      this.subjects[idx].condition = conditionTypes[0];
      this.subjects[idx].prePaymentTypes = [].concat(
        this.options.prepaymentTypes
      );
    },
    findObjectDetail(type) {
      return this.options.objectDetails.find(el => {
        return el.value === type;
      });
    },
    disabledOptionsDetails(length) {
      /*
       *  При добавлении предмета идет
       *  блокировка option Оборудование (equipment) если выбран любой другой предмет лизига
       *  И блокировка всех остальных options если выбран option Оборудование
       */
      if (length >= 1) {
        if (this.subjects[0].type === "equipment") {
          this.objectDetails.forEach(el => {
            if (el.value !== "equipment") {
              el.disabled = true;
            }
          });
        } else {
          this.objectDetails.forEach(el => {
            if (el.value === "equipment") {
              el.disabled = true;
            }
          });
        }
      }
    },
    unDisabledOptionsDetails(length) {
      if (length === 1) {
        this.objectDetails.forEach(el => (el.disabled = false));
      }
    },
    addSubject() {
      let validFields = [];
      let idx = this.lengthSubjects;

      for (let i = 0; i < this.lengthSubjects; i++) {
        validFields.push(this.$refs[`type_${i}`][0].validate());
        validFields.push(this.$refs[`cost_${i}`][0].validate());
        validFields.push(this.$refs[`prepayment_${i}`][0].validate());
        if (this.showSubType) {
          validFields.push(this.$refs[`subType_${i}`][0].validate());
        }
      }

      if (validFields.includes(false)) {
        this.$refs.formOffer.validate();
        return false;
      }

      this.disabledOptionsDetails(idx);
      this.subjects.push({
        type: "",
        subType: "",
        condition: null,
        brand: "",
        model: "",
        equipment: "",
        cost: "",
        prepayment: "",
        lowerPercent: "",
        lowerMoney: "",
        discount: "",
        conditionTypes: [],
        prePaymentTypes: [],
        PF: {},
        needReg: null,
        needPF: null,
        sizeCost: 6,
        sizeLower: 6,
        _finalCost: null,
        _finalPayment: null
      });
      this.setDefaultPropsSubject(idx);
    },
    deleteSubject(idx) {
      this.subjects.splice(idx, 1);
      this.idxMutationSubject = this.lengthSubjects - 1;
      this.unDisabledOptionsDetails(this.lengthSubjects);
      this.changeRate();
    },
    setSize(idx, type) {
      if (type === "sizeCost") {
        this.subjects[idx][type] =
          this.subjects[idx].cost && this.subjects[idx].prepayment ? 5 : 6;
        this.finalPayment(idx);
      }

      if (type === "sizeLower") {
        this.subjects[idx][type] =
          this.subjects[idx].lowerPercent && this.subjects[idx].lowerMoney
            ? 5
            : 6;
        this.finalCost(idx);
      }
    },
    finalCost(idx) {
      let cost = this.subjects[idx].cost;
      let discountMoney = this.subjects[idx].lowerMoney;

      this.subjects[idx]._finalCost = costFilter(cost - discountMoney);
    },
    finalPayment(idx) {
      let cost = this.subjects[idx].cost;
      let prepayment = this.subjects[idx].prepayment;

      this.subjects[idx]._finalPayment = costFilter((cost / 100) * prepayment);
    },
    setRate() {
      // if (this.financing579 || this.financingCR579) {
      //   return this.financingRate;
      // }
      if (this.cashRate) {
        return this.cashRate;
      } else if (this.generalParams.rate) {
        return this.generalParams.rate.toFixed(2);
      }

      return null;
    }
  },
  mounted() {
    this.pledge.length ? (this.subjects = [].concat(this.pledge)) : false;
    this.generalParams = Object.assign({}, this.general);
    this.objectDetails = [].concat(this.options.objectDetails);
    this.langDatePiker = CONST.LOCALE[this.$i18n.locale].toLowerCase();
    this.generalParams.rate = this.setRate();
    this.setDefaultPropsSubject(0);
    if (this.general.typeCompens && this.general.percentComp) {
      this.compensationPartner = true;
    }
    if (this.general.indRate) this.individualRate = true;
    if (this.general.indPrComm) this.individualCommission = true;
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
  }
};
</script>

<style lang="scss">
#formOffer {
  position: relative;
  .form-subtitle {
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 600;
    color: rgb(0 0 0 / 67%);
  }
  #deleteSubject.v-btn {
    /*&:hover::before,
    &:focus::before {
      opacity: 0;
    }*/
    .v-icon {
      margin-right: 1px;
      margin-top: 1px;
    }
  }
  .loader-wrap {
    position: relative;
    &__financing-rate {
      height: 60px;
      margin-top: -25px;
    }
  }
}
#condition,
#financing,
#indType {
  .v-radio {
    &.v-radio--is-disabled .v-label {
      color: rgba(0, 0, 0, 0.38);
    }
    .v-label {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
.subject {
  &-box {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
}
.additional-services .v-label {
  font-size: 15px;
}
#stateGuarantee {
  .v-input {
    width: 200px;
    .v-messages {
      display: none;
    }
    &__slot {
      margin-bottom: 0;
    }
  }
  a {
    color: #75af26;
    text-decoration: none;
    margin-left: 15px;
    padding-top: 2px;
    transition: color 0.2s ease;
    &:hover {
      color: #6da323;
    }
    .v-icon {
      font-size: 18px;
      color: inherit;
    }
  }
}
#individual-conditions {
  flex-direction: column;
  .v-input.detail-checkbox {
    &.v-input--is-disabled .v-label {
      color: rgba(0, 0, 0, 0.38);
    }
    .v-label {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .v-input--radio-group .v-messages {
    display: none;
  }
}
</style>
