<template>
  <v-form
    id="formClient"
    autocomplete="off"
    ref="formClient"
    lazy-validation
    @submit.prevent=""
  >
    <v-container class="px-0 py-5">
      <template v-if="stepMode === 'program'">
        <v-row>
          <v-col v-if="!isAllPrograms" class="mb-3 mt-2">
            <p class="error--text">
              {{
                authEsa
                  ? $t("error.EMPTY_PROGRAMS_ESA")
                  : $t("error.EMPTY_PROGRAMS")
              }}
            </p>
          </v-col>
          <v-col v-else class="mb-3">
            <v-radio-group
              v-model="typeFinancing"
              id="condition"
              class="mt-1 pl-0"
              dense
              column
              @change="changeTypesFinancing"
            >
              <v-radio
                v-for="n in allTypesFinancing"
                :key="n.value"
                :label="n.label"
                :value="n.value"
                :disabled="n.disabled"
                class="mr-4"
                color="privat"
              >
                <template v-slot:label>
                  <span class="mr-3">{{ n.label }}</span>
                  <v-tooltip right max-width="220">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="grey" dense>
                        mdi-information
                      </v-icon>
                    </template>
                    <span>{{ n.description }}</span>
                  </v-tooltip>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="5"
            lg="4"
            xl="3"
            class="d-flex justify-space-between"
          >
            <v-btn
              id="back"
              class="mr-4"
              elevation="1"
              color="blue-grey lighten-5"
              width="37%"
              height="39px"
              @click.native="returnMode('dealer')"
            >
              <span>{{ $t("action.back") }}</span>
            </v-btn>
            <v-btn
              id="continue"
              elevation="1"
              color="privat"
              width="57%"
              height="39px"
              :dark="!typeFinancing ? false : true"
              :disabled="!isAllPrograms || !typeFinancing"
              @click.native="$emit('stepMode', 'client')"
            >
              <span>{{ $t("action.continue") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-if="stepMode === 'dealer'">
        <v-row>
          <v-col cols="12" sm="6" md="5" lg="4" xl="3">
            <v-switch
              v-model="sellerNeed"
              id="sellerNeed"
              class="mt-0"
              color="privat"
              @change="changeSellerNeed"
              :disabled="loading"
            >
              <template v-slot:label>
                <label for="sellerNeed" class="ml-3">
                  {{ $t("label.sellerNeed") }} -
                  <span v-if="!sellerNeed">{{ $t("label.false") }}</span>
                  <span v-if="sellerNeed">{{ $t("label.true") }}</span>
                </label>
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="5" lg="4" xl="3">
            <div id="select-wrap" class="mb-6">
              <Multiselect
                v-model="selectedSeller"
                :options="sellers"
                :loading="isLoading"
                :disabled="loading || !sellerNeed"
                :close-on-select="true"
                :hide-selected="false"
                :allow-empty="false"
                :internal-search="true"
                label="label"
                track-by="label"
                :placeholder="$t('placeholder.seller')"
                select-label=""
                selected-label=""
                deselect-label=""
                @search-change="findSeller($event)"
                @close="isTouched = true"
                :class="{ invalid: isSellerRequired }"
              >
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.okpo }} -
                  {{ props.option.name }}
                </template>
                <template slot="option" slot-scope="props">
                  <span class="option__text ml-2">
                    {{ props.option.name }}
                  </span>
                  <br />
                  <span class="option__label ml-2 d-flex align-center">
                    <v-icon color="privat">mdi-check</v-icon>
                    {{ `${props.option.okpo}` }}
                  </span>
                </template>
                <template slot="placeholder">
                  {{ $t(`label.chooseSeller`) }}
                </template>
              </Multiselect>
              <span class="single__legend">
                {{ $t(`label.seller`) }}
              </span>
              <span class="typo__label error--text" v-if="isSellerRequired">
                {{ $t("rules.required") }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-6">
          <v-col
            cols="12"
            sm="6"
            md="5"
            lg="4"
            xl="3"
            class="d-flex justify-space-between"
          >
            <v-btn
              id="back"
              class="mr-4"
              elevation="1"
              color="blue-grey lighten-5"
              width="37%"
              height="39px"
              @click.native="$router.push('/orders')"
            >
              <span>{{ $t("action.back") }}</span>
            </v-btn>
            <v-btn
              id="continue"
              elevation="1"
              color="privat"
              width="57%"
              height="39px"
              :loading="loading"
              :dark="!!selectedSeller || !sellerNeed"
              @click.native="$emit('stepMode', 'program')"
              :disabled="!selectedSeller && sellerNeed"
            >
              {{ $t("action.continue") }}
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  :size="22"
                  :width="2"
                  :value="75"
                  color="white"
                ></v-progress-circular>
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-if="stepMode === 'client'">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="5"
            lg="4"
            xl="3"
            class="d-flex align-center"
          >
            <v-text-field
              v-model="client.okpo"
              id="clientOkpo"
              :rules="rules.okpo"
              :disabled="client.identified"
              :label="
                !client.okpo
                  ? $i18n.t('label.okpo_holder')
                  : $i18n.t('label.okpo_client')
              "
              maxlength="10"
              color="privat"
              autofocus
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="client.orgName" class="mt-n3 mb-3">
          <v-col cols="12" sm="6" md="5" lg="4" xl="3">
            <span>{{ client.orgName }}</span>
          </v-col>
        </v-row>
        <v-row v-if="client.identified">
          <v-col cols="12" sm="6" md="5" lg="4" xl="3">
            <v-switch
              v-model="clientNear"
              id="clientNear"
              class="mt-0"
              color="privat"
              :disabled="client.sendPhone"
            >
              <template v-slot:label>
                <label for="clientNear" class="ml-3">
                  {{ $t("label.clientNear") }}
                  <span v-if="!clientNear">{{ $t("label.false") }}</span>
                  <span v-if="clientNear">{{ $t("label.true") }}</span>
                </label>
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <div v-if="clientNear">
          <v-row>
            <v-col cols="12" sm="7" md="6" lg="5" xl="3">
              <p>{{ $t("offer.chooseClientPhone") }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="5" lg="4" xl="3">
              <v-select
                v-model="client.phoneId"
                id="clientPhone"
                :items="client.phones"
                item-text="maskedPhone"
                item-value="id"
                :rules="rules.phone"
                :disabled="client.sendPhone"
                :label="$i18n.t('label.phone')"
                color="privat"
                autofocus
                required
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="client.sendPhone">
            <v-col cols="8" sm="4" md="3" xl="2">
              <v-text-field
                v-model="client.codeOtp"
                id="clientOtp"
                :rules="rules.codeOtp"
                :disabled="client.otpSuccess"
                :label="$i18n.t('label.otp')"
                maxlength="4"
                color="privat"
                autofocus
                required
                outlined
                dense
                @mousedown="isValidOtp = true"
                @keydown="isValidOtp = true"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="pt-2 pl-1" v-if="!isValidOtp">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" :color="colorTooltip">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span
                  v-html="
                    $i18n.t('offer.tooltip.badOtp', { counter: dispatchOtp })
                  "
                ></span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="5"
              lg="4"
              xl="3"
              class="d-flex justify-space-between"
            >
              <v-btn
                id="cancel"
                class="mr-4"
                elevation="1"
                color="blue-grey lighten-5"
                width="37%"
                height="39px"
                :disabled="loading"
                @click.native="clearPropsStepOne(false)"
              >
                <span>{{ $t("action.cancel") }}</span>
              </v-btn>
              <v-btn
                id="confirm"
                elevation="1"
                color="privat"
                width="57%"
                height="39px"
                :loading="loading"
                v-if="!client.otpSuccess"
                @click.native="!client.sendPhone ? sendPhone() : sendCodeOtp()"
              >
                <span class="white--text">
                  {{
                    !client.sendPhone
                      ? `${$t("action.send")} код`
                      : $t("action.confirm")
                  }}
                </span>
                <template v-slot:loader>
                  <v-progress-circular
                    indeterminate
                    :size="22"
                    :width="2"
                    :value="75"
                    color="white"
                  ></v-progress-circular>
                </template>
              </v-btn>
              <v-btn
                id="continue"
                elevation="1"
                color="privat"
                width="57%"
                height="39px"
                v-if="client.otpSuccess"
                @click.native="nextStep"
              >
                <span class="white--text">{{ $t("action.continue") }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-row class="mb-6" v-else>
          <v-col
            cols="12"
            sm="6"
            md="5"
            lg="4"
            xl="3"
            class="d-flex justify-space-between"
          >
            <v-btn
              id="back"
              class="mr-4"
              elevation="1"
              color="blue-grey lighten-5"
              width="37%"
              height="39px"
              v-if="!client.identified"
              @click.native="returnMode('program')"
            >
              <span>{{ $t("action.back") }}</span>
            </v-btn>
            <v-btn
              id="continue"
              elevation="1"
              color="privat"
              width="57%"
              height="39px"
              dark
              @click.native="!client.identified ? initClient() : nextStep()"
            >
              <span v-if="!client.identified">
                {{ $t("action.confirm") }}
              </span>
              <span v-if="client.identified">
                {{ $t("action.continue") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <base-snackbar :props="snack" :show="show" @close="show = false" />
    </v-container>
  </v-form>
</template>

<script>
import showSnack from "@/mixins/showSnack.mixins";
import * as offerService from "@/services/offer.service";
import { addDealer, searchDealer } from "@/services/offer.service";
import errorHandler from "@/utils/errorHandler";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import changeTextMultiselect from "@/utils/changeTextMultiselect";
import CONST from "@/utils/constants";
import createMockData from "@/mock/mockNewData";

export default {
  name: "OfferStepOne",
  mixins: [showSnack],
  props: {
    allPrograms: {
      type: Array,
      default: () => []
    },
    client: {
      type: Object,
      default: () => {}
    },
    toggleLoader: Boolean,
    stepMode: String
  },
  data() {
    return {
      loading: false,
      isValidOtp: true,
      possibleDispatch: 3,
      usedDispatch: 0,
      colorsTooltips: ["orange darken-1", "red darken-2"],
      sellerNeed: true,
      isLoading: false,
      isTouched: false,
      selectedSeller: null,
      sellers: [],
      localSellersList: [],
      typeFinancing: null
    };
  },
  components: {
    Multiselect
  },
  watch: {
    toggleLoader() {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters([
      "getPointOfEntry",
      "getUser",
      "getOrdersAndSettingsOptions",
      "devEnv"
    ]),
    rules() {
      return {
        phone: [v => !!v || this.$i18n.t("rules.phone.choose")],
        codeOtp: [
          v => !!v || this.$i18n.t("rules.required"),
          v => /^[0-9]{4}$/.test(v) || this.$i18n.t("rules.codeOtp.correct"),
          v => (v && this.isValidOtp) || this.$i18n.t("rules.codeOtp.invalid")
        ],
        okpo: [
          v => !!v || this.$i18n.t("rules.required"),
          v => /^[0-9]{8,10}$/.test(v) || this.$i18n.t("rules.okpo.correct")
        ]
      };
    },
    clientNear: {
      get() {
        return this.client.near;
      },
      set(val) {
        this.client.near = val;
        !val ? (this.client.phoneId = null) : false;
        return val;
      }
    },
    colorTooltip() {
      let index = this.usedDispatch - 1;

      return this.colorsTooltips[index];
    },
    dispatchOtp() {
      let counter = this.possibleDispatch - this.usedDispatch;
      let text =
        counter === 1
          ? this.$i18n.t("app.attempt")
          : this.$i18n.t("app.attempts");
      return `${counter} ${text}`;
    },
    formClient() {
      return this.$refs.formClient;
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    isSellerRequired() {
      return this.isTouched && !this.selectedSeller;
    },
    isAllPrograms() {
      return !!this.allTypesFinancing.length;
    },
    allTypesFinancing() {
      if (this.authEsa && this.selectedSeller) {
        if (!this.selectedSeller.programs.length) return this.allPrograms;

        return this.selectedSeller.programs
          .map(el => {
            return {
              ...el,
              name: this.$i18n.t(`offer.allTypeFinancing.${el.value}`),
              label: this.$i18n.t(`offer.allTypeFinancing.${el.value}`),
              description: this.$i18n.t(
                `offer.tooltip.typeFinancing.${el.value}`
              )
            };
          })
          .sort((a, b) => {
            return a.label?.localeCompare(b.label);
          });
      }

      return this.allPrograms;
    }
  },
  methods: {
    returnMode(mode) {
      if (!this.authEsa) {
        if (mode === "program") {
          this.$emit("stepMode", mode);
          this.clearPropsStepOne(false);
        } else {
          this.$router.push("/orders");
        }
      } else {
        this.$emit("stepMode", mode);
        if (mode === "dealer") {
          this.typeFinancing = null;
          this.timeOut = setTimeout(() => {
            changeTextMultiselect.call(this);
          }, 100);
        }
      }
    },
    nextStep() {
      this.$emit("step", [2, false]);
    },
    changeTypesFinancing() {
      console.log("changeTypesFinancing typeFinancing -", this.typeFinancing);
      this.$emit("updateProgram", this.typeFinancing);
    },
    initClient() {
      if (this.devEnv) {
        const data = createMockData("addCalcTaskEsa");

        this.$emit("progress", true);
        this.client.cpId = data.content.cpId;
        this.getStateTask("initClient");
        // mock
        return;
      }

      if (this.formClient.validate()) {
        this.$emit("progress", true);
        let data = {
          clientOkpo: this.client.okpo,
          program: this.typeFinancing,
          authEsa: this.authEsa
        };

        if (this.authEsa) {
          data.sellerOkpo = this.selectedSeller?.okpo || null;
        }

        offerService
          .getClient(data) //this.client.okpo, this.program, this.authEsa
          .then(resp => {
            let data = resp.data;

            this.client.cpId = data.content.cpId;
            this.getStateTask("initClient");
          })
          .catch(err => {
            let errorCode = errorHandler(err, "initClient");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            this.$emit("progress", false);
            console.log("catch initClient");
          });
      }
    },
    sendPhone() {
      if (this.formClient.validate()) {
        this.loading = true;
        offerService
          .sendPhone(this.client.cpId, this.client.phoneId, this.authEsa)
          .then(() => {
            this.getStateTask("sendPhone");
          })
          .catch(err => {
            let errorCode = errorHandler(err, "sendPhone");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            this.loading = true;
            console.log("catch sendPhone");
          });
      }
    },
    sendCodeOtp() {
      if (this.formClient.validate()) {
        this.loading = true;
        offerService
          .sendOtp(this.client.cpId, this.client.codeOtp, this.authEsa)
          .then(() => {
            this.usedDispatch += 1;
            this.getStateTask("sendCodeOtp");
          })
          .catch(err => {
            let errorCode = errorHandler(err, "sendCodeOtp");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            this.loading = true;
            console.log("catch sendCodeOtp");
          });
      }
    },
    getStateTask(name) {
      if (this.devEnv) {
        const data = createMockData("currentConditionESA");

        this.checkState(data, name);
        // mock
        return;
      }

      offerService
        .currentCondition(this.client.cpId, this.authEsa)
        .then(resp => {
          this.checkState(resp.data, name);
        })
        .catch(err => {
          let errorCode = errorHandler(err, name);

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          this.$emit("progress", false);
          this.loading = false;
          console.log("catch getStateTask");
        });
    },
    checkState(data, name) {
      let error = data?.errorMessage;
      let errMsg = error ? error[0] : [this.$i18n.t(`error.${name}`)];
      let state = data?.content?.state ?? "";
      let finalState = data?.finalRespState;

      if (!finalState) {
        let timeout = setTimeout(() => {
          this.getStateTask(name);
          clearTimeout(timeout);
        }, 2000);

        return false;
      }

      if (name === "initClient" && state === "INIT_CLIENT_DATA_FINISHED") {
        let clientData = data.content.clientData;

        this.client.near = true;
        this.client.identified = true;
        this.client.phones = clientData.phoneDataList;
        this.client.emails = clientData.emailDataList;
        this.client.orgName = clientData.orgName;
        this.$emit("progress", false);
        this.loading = false;
      }

      if (name === "sendPhone" && state === "CONFIRM_OTP_SENT") {
        this.client.sendPhone = true;
        this.loading = false;
      }

      if (name === "sendCodeOtp") {
        let stateOtp =
          data.content.confirmData?.otpVerifyDto?.state ?? "sendOtp";

        if (stateOtp === "OK") {
          this.client.otpSuccess = true;
          this.nextStep();
        } else if (stateOtp === "BAD" && this.usedDispatch <= 2) {
          this.isValidOtp = false;
          this.formClient.validate();
        } else {
          this.clearPropsStepOne(true);
          this.showSnack("error", [this.$i18n.t(`error.${stateOtp}`)]);
        }
        this.loading = false;
        return false;
      }

      if (error && state.includes("ERROR")) {
        console.log("checkState error - ", error);
        this.showSnack("error", [this.$i18n.t(`error.${errMsg}`)]);
        name === "initClient"
          ? this.$emit("progress", false)
          : (this.loading = false);
        return false;
      }
    },
    clearPropsStepOne(near) {
      this.client.phoneId = null;
      this.client.sendPhone = false;
      this.client.codeOtp = null;
      this.client.otpSuccess = false;
      this.client.near = near;
      this.isValidOtp = true;
      this.usedDispatch = 0;
    },
    changeSellerNeed() {
      if (!this.sellerNeed) {
        this.isTouched = false;
        this.selectedSeller = null;
        this.sellers = this.localSellersList;
      }
    },
    findSeller(val) {
      if (this.isLoading) return;
      if (val && !CONST.REG_EXP_OPKO.test(val)) {
        return;
      }

      if (val?.length >= 8 && val?.length <= 10) {
        this.isLoading = true;
        this.searchSeller(val);
      }
    },
    searchSeller(val) {
      searchDealer(val)
        .then(res => {
          let data = res.data;
          let foundSeller = {
            ...data,
            label: `${data.okpo} ${data.name}`
          };

          this.sellers = this.localSellersList.filter(
            el => el.okpo !== data.okpo
          );
          this.sellers.push(foundSeller);
          this.selectedSeller = foundSeller;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "GET_DATA");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch searchSeller");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addSeller() {
      // this.loading = true;
      if (!this.selectedSeller && !this.sellerNeed) {
        // this.$emit("step", [2, false]);
        this.$emit("stepMode", "program");
        return;
      }
      addDealer(this.client.cpId, this.selectedSeller.okpo)
        .then(() => {
          // this.$emit("step", [2, false]);
          this.$emit("stepMode", "program");
        })
        .catch(err => {
          let errorCode = errorHandler(err, "other");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          this.loading = false;
          console.log("catch addSeller");
        });
    },
    createListSellers() {
      let allSellers = this.getOrdersAndSettingsOptions?.ogrDataFilterCps || [];

      return allSellers.map(el => {
        return {
          ...el,
          name: el.orgName,
          label: `${el.okpo} ${el.orgName}`
        };
      });
    }
  },
  mounted() {
    if (this.authEsa) {
      this.localSellersList = this.createListSellers();
      this.sellers = this.localSellersList;
      this.$emit("stepMode", "dealer");
    } else {
      this.$emit("stepMode", "program");
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
  }
};
</script>
